


























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Service } from '@/repository/kontent/stores/serviceStore';
import { LinksetStore } from '@/repository/kontent/stores/sitemapStore';
import { reactive } from '@vue/composition-api';
import I18n from '@/i18n/index';

@Component({
  components: {},
})
export default class LocalNav extends Vue {
  @Prop(String) selectTarget!: string;
  private i18n = I18n;
  private serviceLink = reactive(new LinksetStore());

  private async created() {
    await this.getServiceLink(this.selectTarget);
  }

  @Watch('$route')
  async onRouteChanged(to: any): Promise<void> {
    await this.getServiceLink(this.selectTarget);
  }

  private async getServiceLink(target: string) {
    this.serviceLink.initQuery();
    this.serviceLink.setFilter('system.name', 'サービス');
    this.serviceLink.setDepth(2);
    await this.serviceLink.restore();
  }

  private get serviceList() {
    const result = this.serviceLink.list[0]?.list.value
      .map((y) => {
        return y.list?.value;
      })
      .reduce((a, b) => {
        return a.concat(b);
      }) as Array<Service>;
    return [...Array.from(new Set(result))];
  }
}
