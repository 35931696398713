




























































import { Component, Watch, Vue } from 'vue-property-decorator';
import { ServiceStore, Service } from '@/repository/kontent/stores/serviceStore';
import { reactive } from '@vue/composition-api';
import LocalNav from './LocalNav.vue';
import I18n, { Languages } from '@/i18n/index';
import { i18n } from 'dateformat';

@Component({
  components: {
    LocalNav,
  },
})
export default class Index extends Vue {
  private i18n = I18n;
  @Watch('$route')
  async onRouteChanged(to: any): Promise<void> {
    await this.getServiceDeatail(to.params.name);
  }

  private async created() {
    await this.getServiceDeatail(this.targetName);
  }

  private serviceDetailData = reactive(new ServiceStore());

  private get targetName() {
    return this.$route.params.name;
  }
  private get targetLang() {
    return this.$route.params.language as Languages;
  }

  private get summary() {
    return this.detail?.summary.value;
  }
  private get detail() {
    return this.serviceDetailData.list[0];
  }

  private get html() {
    return this.detail?.html.value;
  }

  private get body() {
    return this.detail?.body.value;
  }

  private get title() {
    return this.detail?.title.value;
  }

  private get subtitle() {
    return this.detail?.subtitle.value;
  }

  private async getServiceDeatail(target: string) {
    this.serviceDetailData.initQuery();
    this.serviceDetailData.setFilter('elements.routername', target);
    await this.serviceDetailData.restore();

    if (!this.body && !this.html) {
      this.$router.push(`/${this.i18n.language}/`);
    }

    const titleVal = this.title + ' | ' + this.$i18n.tc('corporateName');
    document.title = titleVal;
    document.querySelector("meta[property='og:title']")?.setAttribute('content', titleVal);

    document.querySelector("meta[name='description']")?.setAttribute('content', this.summary);
    document.querySelector("meta[property='og:description']")?.setAttribute('content', this.summary);
  }
}
