import Vue from 'vue';
import { reactive } from '@vue/composition-api'
import Store from './base/store';
import { Service } from "../models/service";


class ServiceStore extends Store<Service>{
  constructor(){
    super('service')
  }
}

const x = new Store<Service>('service');
x.restore();

export { ServiceStore, Service };
export default reactive(x);
